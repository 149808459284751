<div class="background-image-container mt-ncs">
  <div class="container">
    <div class="jumbotron jumbotron-fluid">
      <div class="d-flex flex-wrap">
        <div>
          <h1 class="display-3">{{ 'main.title' | translate }}</h1>
          <p class="lead">{{ 'main.subtitle' | translate }}:</p>
        </div>
      </div>
    </div>
  </div>
  <picture class="background-image">
    <source type="image/webp" srcset="assets/dspace/images/background_casagrande.svg">
    <div class="banner-image" style="background-image: url(assets/dspace/images/background_casagrande.svg)"></div>
  </picture>
</div>
<style>
</style>
